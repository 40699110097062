import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './_helpers';
import { MainLayoutComponent } from './_layouts/main-layout/main-layout.component';

const inspectionModule = () =>
  import('./inspection/inspection.module').then((x) => x.InspectionModule);

const galleryModule = () =>
  import('./gallery/gallery.module').then((x) => x.GalleryModule);

const videoModule = () =>
  import('./video/video.module').then((x) => x.VideoModule);

const plantModellingModule = () =>
  import('./plant-modelling/plant-modelling.module').then(
    (x) => x.PlantModellingModule
  );

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [{ path: '', component: DashboardComponent }],
  },
  { path: 'login', component: LoginComponent },
  {
    path: 'inspection',
    loadChildren: inspectionModule,
    canActivate: [AuthGuard],
  },
  { path: 'photo', loadChildren: galleryModule, canActivate: [AuthGuard] },
  {
    path: 'plant',
    loadChildren: plantModellingModule,
    canActivate: [AuthGuard],
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
