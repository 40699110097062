import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  @ViewChild('commercialVideo') commercialVideo: any;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    const video: HTMLVideoElement = this.commercialVideo.nativeElement;
    video.volume = 0.2;
  }

  openPotree() {
    window.open(
      'https://abptemp.s3-ap-southeast-1.amazonaws.com/FPG+OLEOCHEMICAL/3D+Point+Cloud/Potree/Watertank12/index.html',
      '_blank'
    );
  }
}
