<div class="container-fluid" style="height: calc(100vh - 55px)">
  <div class="row pl-1 pr-1 pt-3">
    <div
      class="col-12 col-sm-6 d-flex justify-content-center text-center align-items-center flex-column h-100"
    >
      <div class="col-12">
        <div class="row w-100 pl-3 text-justify" style="font-size: 15px">
          <h4>Project Summary</h4>
          <p>
            FPG Oleochemicals Sdn. Bhd. required confined space inspection to be
            carried out during their mini shutdown in August 2020.
          </p>
          <p>
            FPG Oleochemicals needed their facilities to be inspected to ensure
            reliability of their equipment are in order to maintain smooth
            operation. Aerodyne Oil & Gas with its expertise in inspection and
            drone technology, answers to the requirement and carried out the
            inspection at FPG facility. 
          </p>
          <p>
            Aerodyne Oil & Gas inspection team mobilized to FPG Oleochemicals to
            Gebeng, Pahang on 7th August 2020 and completed the job on 11th
            August 2020. A team consisted of 3 pax of manpower, namely 2
            competent drone pilots and 1 pax of certified API 510 inspector were
            sent to perform the confined space visual inspection. The industrial
            grade drone Elios2 from Flyability was utilised to perform the
            confined space inspection. 
          </p>
        </div>
        <div class="pt-5 d-block d-sm-none"></div>
        <div class="">
          <video
            #commercialVideo
            width="100%"
            height="100%"
            controls
            preload="auto"
            class="border-box"
          >
            <source
              src="https://aerodyne-petronas-staging.s3-ap-southeast-1.amazonaws.com/FPG+UAV+INSPECTION/COMMERCIAL+VIDEO/FPGv6.m4v"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 d-flex align-items-center flex-column h-100">
      <div class="container-fluid h-100">
        <div
          class="mb-3 d-flex flex-row justify-content-center align-items-center text-center"
          style="height: 25%; font-size: small"
        >
          <div
            class="card mr-2 border-box"
            style="width: 25%; border: 1px black"
          >
            <div class="card-body" style="padding: 1.4rem 0 1.4rem 0">
              <h1 class="card-title">3</h1>
              <p class="card-text text-muted">Total Inspected Area</p>
            </div>
          </div>
          <div
            class="card mr-2 border-box"
            style="width: 25%; background-color: #e69390"
          >
            <div class="card-body" style="padding: 1.4rem 0 1.4rem 0">
              <h1 class="card-title">0</h1>
              <p class="card-text" style="opacity: 0.5">Major Findings</p>
            </div>
          </div>
          <div
            class="card mr-2 border-box"
            style="width: 25%; background-color: #e4b676"
          >
            <div class="card-body" style="padding: 1.4rem 0 1.4rem 0">
              <h1 class="card-title">2</h1>
              <p class="card-text" style="opacity: 0.5">Minor Findings</p>
            </div>
          </div>
          <div
            class="card border-box"
            style="width: 25%; background-color: #1b9bbb"
          >
            <div class="card-body" style="padding: 1.4rem 0 1.4rem 0">
              <h1 class="card-title">2</h1>
              <p class="card-text" style="opacity: 0.5">Recommendations</p>
            </div>
          </div>
        </div>
        <!-- Inspection Findings -->
        <div class="p-2 mb-2 border-box">
          <div class="row h-100">
            <div class="col-5 h-100">
              <div
                class="d-flex flex-column justify-content-center align-items-start h-25"
              >
                <div class="row pl-4">
                  <img
                    class="icon-size mr-2"
                    src="../../assets/icons/task.svg"
                    alt=""
                  />
                  Inspection Findings
                </div>
                <button
                  type="button"
                  class="btn btn-light border-box button-size row ml-4"
                  [routerLink]="['/inspection/report/chimney']"
                >
                  Chimney
                </button>
                <button
                  type="button"
                  class="btn btn-light border-box button-size row ml-4"
                  [routerLink]="['/inspection/report/reactor']"
                >
                  Reactor
                </button>
                <button
                  type="button"
                  class="btn btn-light border-box button-size row ml-4"
                  [routerLink]="['/inspection/report/tank']"
                >
                  Tank
                </button>
              </div>
            </div>
            <div
              class="col-5 d-flex flex-column justify-content-start align-items-start"
            >
              <div
                class="text-justify"
                style="font-size: 12px; padding-top: 2rem !important"
              >
                <span>
                  Confined space visual inspection by using drone at Heater
                  chimney, Ractor, and Tank S1108 at FPG Oleochemical Facility
                  in Kuantan Pahang,August 2020. All verified inspection report,
                  inspection photos and videos can be found here.
                </span>
              </div>
            </div>
            <div
              class="col-2 d-flex flex-column justify-content-end align-items-end"
            >
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-arrow-right"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                />
              </svg>
            </div>
          </div>
        </div>
        <!-- 3D Modelling -->
        <div class="p-2 mb-2 border-box">
          <div class="row h-100">
            <div class="col-5 h-100">
              <div
                class="d-flex flex-column justify-content-center align-items-start"
              >
                <div class="row pl-4">
                  <img
                    class="icon-size mr-2"
                    src="../../assets/icons/cube.svg"
                    alt=""
                  />
                  Plant 3D Modelling
                </div>
                <button
                  type="button"
                  class="btn btn-light border-box button-size row ml-4"
                  [routerLink]="['/plant/model/chimney']"
                >
                  Chimney
                </button>
                <button
                  type="button"
                  class="btn btn-light border-box button-size row ml-4"
                  [routerLink]="['/plant/model/tank']"
                >
                  Tank
                </button>
                <span class="button-size row ml-4"></span>
              </div>
            </div>
            <div
              class="col-5 d-flex flex-column justify-content-start align-items-start"
            >
              <div
                class="text-justify"
                style="font-size: 12px; padding-top: 2rem !important"
              >
                <span>
                  Aerodyne team has taken the advantage of drone capabilities
                  during the inspection, to create simple 3D modelling of the
                  inspection area. This 3D modelling is a sample from our
                  various capabilities in technology in industry.
                </span>
              </div>
            </div>
            <div
              class="col-2 d-flex flex-column justify-content-end align-items-end"
            >
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-arrow-right"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                />
              </svg>
            </div>
          </div>
        </div>
        <!-- Photo Gallery -->
        <div class="p-2 border-box">
          <div class="row h-100">
            <div class="col-5 h-100">
              <div
                class="d-flex flex-column justify-content-center align-items-start h-25 pl-4"
              >
                <div class="row">
                  <img
                    class="icon-size mr-2"
                    src="../../assets/icons/photo.svg"
                    alt=""
                  />
                  Photo Gallery
                </div>
              </div>
              <div
                class="d-flex flex-column justify-content-center align-items-start h-75 pl-4"
              >
                <div class="row box-size pr-3">
                  <div class="col-6">
                    <img
                      class="gallery-img-size pointer"
                      src="../../assets/img/pngday1.JPG"
                      alt=""
                      [routerLink]="['/photo/gallery/1']"
                    />
                  </div>
                  <div class="col-6">
                    <img
                      class="gallery-img-size pointer"
                      src="https://aerodyne-petronas-staging.s3-ap-southeast-1.amazonaws.com/FPG+UAV+INSPECTION/Gallery/Day+1/0118.JPG"
                      alt=""
                      [routerLink]="['/photo/gallery/1']"
                    />
                  </div>
                </div>
                <div class="row box-size pr-3">
                  <div class="col-6">
                    <img
                      class="gallery-img-size pointer"
                      src="https://aerodyne-petronas-staging.s3-ap-southeast-1.amazonaws.com/FPG+UAV+INSPECTION/Gallery/Day+1/0104.JPG"
                      alt=""
                      [routerLink]="['/photo/gallery/1']"
                    />
                  </div>
                  <div class="col-6">
                    <img
                      class="gallery-img-size pointer"
                      src="https://aerodyne-petronas-staging.s3-ap-southeast-1.amazonaws.com/FPG+UAV+INSPECTION/Gallery/Day+1/0110.JPG"
                      alt=""
                      [routerLink]="['/photo/gallery/1']"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-5 d-flex flex-column justify-content-start align-items-start"
            >
              <div
                class="text-justify"
                style="font-size: 12px; padding-top: 2rem !important"
              >
                <span>
                  Brief description of photos taken during the inspection work.
                  Photos from surrounding area during inspection is shared as a
                  best memory between Aerodyne and FPG Oleochemical partnership.
                </span>
              </div>
            </div>
            <div
              class="col-2 d-flex flex-column justify-content-end align-items-end"
            >
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-arrow-right"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                />
              </svg>
            </div>
          </div>
        </div>
        <!-- <a [routerLink]="['/photo']">
          <div class="p-4 mb-2 border-box" id="photo">
            <h2 class="pt-3 text-white font-weight-light">Photos</h2>
          </div>
        </a>
        <a [routerLink]="['/video']">
          <div class="p-4 mb-2 border-box" id="video">
            <h2 class="pt-3 text-white font-weight-light">Videos</h2>
          </div>
        </a> -->
      </div>
    </div>
  </div>
</div>

<!-- <a
                    href="https://aerodyne-petronas-staging.s3-ap-southeast-1.amazonaws.com/FPG+UAV+INSPECTION/CHIMNEY+INSPECTION/IMAGES/104_0022.JPG"
                    download
                  >
                    <img
                      src="https://aerodyne-petronas-staging.s3-ap-southeast-1.amazonaws.com/FPG+UAV+INSPECTION/CHIMNEY+INSPECTION/IMAGES/104_0022.JPG"
                      alt="W3Schools"
                      width="104"
                      height="142"
                    />
                  </a> -->
