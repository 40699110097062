<div class="container-fluid h-100">
  <div class="row h-100">
    <!-- Mobile -->
    <div class="col-7 d-none d-sm-block" id="loginImg"></div>
    <div
      class="col-12 col-sm-5 d-flex justify-content-center text-center align-items-center flex-column"
    >
      <div class="container w-75 d-none d-sm-block">
        <div class="logo pb-5">
          <img class="m-4" src="../../assets/img/fpg-logo.jpeg" alt="" />
          <img class="m-4" src="../../assets/img/png-logo.png" alt="" />
        </div>
        <div class="container">
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <input
                type="text"
                formControlName="username"
                class="form-control"
                placeholder="Username"
                [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
              />
              <div
                *ngIf="submitted && f.username.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.username.errors.required">
                  Username is required
                </div>
              </div>
            </div>
            <div class="form-group">
              <input
                type="password"
                formControlName="password"
                class="form-control"
                placeholder="Password"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
              />
              <div
                *ngIf="submitted && f.password.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.password.errors.required">
                  Password is required
                </div>
              </div>
              <div *ngIf="loginError" class="invalid-feedback d-block">
                <div>{{ loginError }}</div>
              </div>
            </div>
            <div>
              <button
                [disabled]="loading"
                class="btn btn-block"
                style="background-color: #07338e; color: whitesmoke"
              >
                <span
                  *ngIf="loading"
                  class="spinner-border spinner-border-sm mr-1"
                ></span>
                Login
              </button>
              <p class="brand-acknowledgement pt-2">
                powered by
                <span class="font-brand"
                  >Vertikaliti <strong>Energy</strong>
                </span>
              </p>
            </div>
          </form>
        </div>
      </div>
      <div class="container w-75 d-block d-sm-none">
        <div>
          <div class="logo pb-5">
            <img
              class="m-4"
              src="../../assets/img/aerodyne_outline_black_icon.png"
              alt=""
            />
          </div>
          <h3>MOBILE VIEW CURRENTLY NOT SUPPORTED</h3>
        </div>
      </div>
    </div>
  </div>
</div>
