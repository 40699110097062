import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service.js';
import { path } from '../../_helpers/common.js';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  public inspection;
  public area;
  public data;
  currentURL: string;
  routerSub: any = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.loadData();
    this.router.events.subscribe((val) => {
      if (this.currentURL !== this.router.url) {
        this.currentURL = this.router.url; // Update the value when a different route is accessed
        this.loadData();
      }
    });

    // this.routerSub = this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     console.log(event, 'ss');

    //     let a = this.route.firstChild.snapshot.data.showClassList;
    //     console.log(a, 's');
    //   }
    // });
  }

  loadData() {
    const { url } = this.router;
    let type = path(url, 2);
    let id = path(url, 3);
    this.inspection = type === 'model' ? 'Plant 3D Modelling' : type;
    this.area = this.inspection === 'gallery' ? `Day ${id}` : id;
    // if (type === 'report') {
    //   this.data = this.data.filter((e) => e.type === 'report' && e.area === id);
    // } else if (type === 'photo') {
    //   this.data = this.data.filter((e) => e.type === 'photo' && e.area === id);
    // } else {
    //   this.data = this.data.filter((e) => e.type === 'video' && e.area === id);
    // }
  }

  logout() {
    this.authService.logout();
  }
}
