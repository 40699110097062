<nav
  class="navbar navbar-expand-lg navbar-light box-shadow"
  style="height: 55px"
>
  <div class="mr-4 pointer" [routerLink]="['/dashboard']">
    <img id="aero-logo" src="../../assets/img/aerodyne-logo-2.jpeg" alt="" />
    <img id="fpg-logo" src="../../assets/img/fpg-logo.jpeg" alt="" />
    <img id="png-logo" src="../../assets/img/png-logo.png" alt="" />
  </div>

  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarNav"
    aria-controls="navbarNav"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div
    class="collapse navbar-collapse border-left"
    id="navbarNav"
    style="padding: 3px"
  >
    <ul class="navbar-nav">
      <li>
        <span style="font-size: 20px">FPG Oleochemicals Inspection Portal</span
        ><br />
        <span
          style="font-size: 12px"
          class="pointer"
          [routerLink]="['/dashboard']"
          >Main Menu</span
        >
        <span *ngIf="inspection" style="font-size: 12px">
          / {{ inspection | titlecase }}</span
        >
        <span *ngIf="area" style="font-size: 12px">
          / {{ area | titlecase }}</span
        >
      </li>
    </ul>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a class="nav-link pointer" (click)="logout()"
          ><img src="assets/icons/power-button.svg"
        /></a>
      </li>
    </ul>
  </div>
</nav>
